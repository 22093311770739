<template>
    <div>
      <transition name="fade-short" mode="out-in">
          <router-view/>
      </transition>
    </div>

</template>

<script>

export default {
  name: 'Executions',
  components: { }
}
</script>
